























import { Component, Vue } from "vue-property-decorator";
import { notificationService } from "@/services/notification.service";
import NotificationDto from "@/contracts/notification/NotificationDto";
import Severity from "@/contracts/notification/Severity";

@Component({
  components: {},
})
export default class NotificationBanner extends Vue {
  severity: Severity[] = [
    { name: "Error", color: "deep-orange accent-4", icon: "mdi-alert-circle" },
    { name: "Warning", color: "orange darken-3", icon: "mdi-alert" },
    { name: "Info", color: "blue darken-3", icon: "mdi-information" },
    { name: "Success", color: "green darken-3", icon: "mdi-check-circle" },
  ];
  relevantNotification: NotificationDto = new NotificationDto();
  relevantSeverity: Severity = new Severity();
  timedNotificationEventId = 0;

  get severityColor(): string | null {
    return this.relevantSeverity.color;
  }

  get severityIcon(): string | null {
    return this.relevantSeverity.icon;
  }

  mounted(): void {
    this.getRelevantNotification();
    this.setUpTimedGetNotification();
  }

  destroy(): void {
    this.clearTimedGetNotification();
  }

  getRelevantNotification(): void {
    notificationService.getRelevantNotification().then((data) => {
      this.relevantNotification = JSON.parse(JSON.stringify(data));
      let foundSeverity = this.severity.find(
        (severityLevel) =>
          severityLevel.name === this.relevantNotification.severityName
      );
      this.relevantSeverity = foundSeverity ? foundSeverity : new Severity();
    });
  }

  setUpTimedGetNotification(): void {
    this.timedNotificationEventId = setInterval(
      () => this.getRelevantNotification(),
      120000
    );
  }

  clearTimedGetNotification(): void {
    clearInterval(this.timedNotificationEventId);
  }
}
