


























import { Component, Vue } from "vue-property-decorator";

@Component
export default class CookieConsentMessage extends Vue {
  get cookiesAccepted(): boolean {
    return (
      localStorage.getItem("cookiesAccepted") &&
      JSON.parse(localStorage.getItem("cookiesAccepted") || "")
    );
  }

  acceptCookies(): void {
    localStorage.setItem("cookiesAccepted", true.toString());
  }
}
