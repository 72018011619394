





















































import DetailComponentBase from "@/app/dynamic-components/details/details-components/detail-component.base";
import { Component, Watch } from "vue-property-decorator";
import OverviewComponent from "@/app/dynamic-components/overviews/overview-component.vue";
import FormWrapper from "@/app/dynamic-components/forms/FormWrapper.vue";
import Loader from "@/components/common/Loader.vue";
import { RenderType } from "@/app/Types";
import { exchangeService } from "@/app/services/exchange.service";
import ExchangeComponent from "@/app/components/exchange/exchange-component.vue";
import { ExchangeEvent } from "@/app/components/exchange/exchange.model";
import { Subject, Subscription } from "rxjs";
import {
  OverviewControl,
  OverviewEvent,
} from "@/app/dynamic-components/overviews/overiew.model";
import {
  DynamicDetailEntry,
  ExchangeDefinition,
} from "@/app/dynamic-components/details/dynamic-detail.model";
import { ExternalContext } from "@/app/contexts/externalContext";
import { Overview } from "@/app/models/overview/overview.model";

export type DetailDocumentsTabMetaData = {
  exchangeDefinitionId?: string;
  overviewDefinitionId?: string;
  dataPath?: string;
  referenceId: string;
  referenceType: string;
};

@Component({
  computed: {
    RenderType() {
      return RenderType;
    },
  },
  components: { ExchangeComponent, Loader, FormWrapper, OverviewComponent },
})
export default class DetailComponentExchanges extends DetailComponentBase<DetailDocumentsTabMetaData> {
  showStepperDialog = false;
  exchangeDefinition: ExchangeDefinition | undefined = undefined;
  private openExistingExchangeId: string | null = null;
  private controlbus: Subject<ExchangeEvent> | null = null;
  private controlbusSubscription: Subscription | null = null;
  involvedParties: any = {};
  private isEditable = false;
  private isLastStep = false;
  private isFirstStep = false;
  private isStepAllowed = false;
  private dossierId = null;

  private canCreateNewExhange = false;
  protected externalContext = new ExternalContext();
  protected overviewControl: OverviewControl | undefined;
  protected additionalMetadata = {};

  protected metadataDatapath: string | undefined;

  constructor() {
    super();
  }

  modifyExchangeResponse: (overview: Overview) => Overview = (
    overview: Overview
  ) => {
    overview._embedded.results = Array.from(overview._embedded.results).map(
      (value) => exchangeService.convertApiExchangeToExchange(value)
    );
    return overview;
  };

  async postEntityChanged(newValue: DynamicDetailEntry) {
    if (!this.entry) return;
    this.metadataDatapath = this.detailContext.resolvePlaceholders(
      this.entry.metadata.dataPath
    );

    //get stepper data
    this.exchangeDefinition = await exchangeService.getExchangeDefinition(
      this.entry.metadata.exchangeDefinitionId
    );
    this.additionalMetadata = {
      definition: this.exchangeDefinition,
      dossier: this.detailContext.data["dossier"],
    };
    console.warn("set resolve additionalmeta", this.additionalMetadata);

    // resolve involved party placeholders with id's from context
    this.entry.metadata.involvedParties.forEach((involvedParty: any) => {
      this.involvedParties[involvedParty.id] =
        this.detailContext.resolveDataPath(involvedParty.contactIdReference);
    });

    // see if user can create new exchanges based on first step party.
    if (
      this.exchangeDefinition.steps?.length > 0 &&
      this.involvedParties[this.exchangeDefinition.steps[0].involvedParty]
    ) {
      const partyOfFirstStep =
        this.involvedParties[this.exchangeDefinition.steps[0].involvedParty];
      const partyDefinitionsOfFirstStep =
        this.exchangeDefinition.involvedParties.filter(
          (value) =>
            value.id === this.exchangeDefinition?.steps[0].involvedParty
        );
      if (partyDefinitionsOfFirstStep.length > 0) {
        const partyDefinitionOfFirstStep = partyDefinitionsOfFirstStep[0];

        if (partyDefinitionOfFirstStep.contactType === "USER") {
          this.canCreateNewExhange =
            this.$store.state.user.contactId === partyOfFirstStep;
        } else {
          this.canCreateNewExhange =
            this.$store.state.user.organizationId === partyOfFirstStep;
        }
      }
    }

    //this.$forceUpdate();
  }

  openDialog() {
    this.dossierId = this.detailContext.resolveDataPath("data.dossierId");
    this.externalContext.inherit(this.detailContext);
    this.showStepperDialog = true;
  }

  recieveControlBus(controlbus: Subject<ExchangeEvent>) {
    if (this.controlbusSubscription) {
      this.controlbusSubscription.unsubscribe();
    }
    this.controlbus = controlbus;
    this.controlbusSubscription = this.controlbus.subscribe((event) => {
      switch (event.action) {
        case "CLOSE":
          this.openExistingExchangeId = null;
          this.showStepperDialog = false;
          if (this.overviewControl) this.overviewControl.refresh();
          break;
        case "UPDATESTEP":
          if (event.metadata.state === "editable") {
            this.isEditable = true;
          } else if (event.metadata.state === "readonly") {
            this.isEditable = false;
          }
          this.isLastStep = event.metadata.isLastStep;
          this.isStepAllowed = event.metadata.isStepAllowed;
          this.isFirstStep = event.metadata.isFirstStep;
          break;
      }
    });
  }

  beforeDestroy(): void {
    if (this.controlbusSubscription) {
      this.controlbusSubscription.unsubscribe();
    }
  }
  openExchange(event: OverviewEvent) {
    this.dossierId = this.detailContext.resolveDataPath("data.dossierId");
    this.externalContext.inherit(this.detailContext);
    if (event.type === "ACTION") {
      this.openExistingExchangeId = event.item.id;
      this.showStepperDialog = true;
    }
  }
}
