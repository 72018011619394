






























































import { Component, Vue } from "vue-property-decorator";
import ActivityDependendViewBase from "@/app/views/activity-dependend-view.base";
import ActivityDefinitionModel from "@/app/models/activity/activity-definition.model";
import MyDossiersView from "@/views/dossiers/MyDossiersView.vue";
import OverviewComponent from "@/app/dynamic-components/overviews/overview-component.vue";
import PageHeader from "@/components/layout/PageHeader.vue";
import AcceptPoliciesInterceptor from "@/components/common/AcceptPoliciesInterceptor.vue";
import AuthenticationInterceptor from "@/components/common/AuthenticationInterceptor.vue";
import router, { Routes } from "@/router";
import {
  defaultDossierSecurity,
  DossierSecurity,
} from "@/app/models/activity/security.model";
import Loader from "@/components/common/Loader.vue";
import { dossierServiceV2 } from "@/app/services/dossier.service";
import ActionTile from "@/components/common/ActionTile.vue";
import { Overview } from "@/app/models/overview/overview.model";
import { deepMergeObjects } from "@/app/helpers/stringpath.helper";

@Component({
  components: {
    ActionTile,
    Loader,
    AuthenticationInterceptor,
    AcceptPoliciesInterceptor,
    PageHeader,
    OverviewComponent,
    MyDossiersView,
  },
})
export default class DossiersOverviewView extends ActivityDependendViewBase {
  public dossierSecurity: DossierSecurity = defaultDossierSecurity("");
  public singleDossierLoading = true;
  public singleDossierNotFound = false;


  async onActivityUpdated(): Promise<any> {
    this.handleDossierSecurity();
    if (this.dossierSecurity.singleDossier) {
      this.navigateToSingleDossier();
    }
  }
  mounted(): void {
    const i = 0;
  }

  navigateToDossierCreation() {
    router.push({ name: Routes.CreateDossierV2 });
  }
  dossierSelected(item: { item: any; index: number }) {
    router.push({
      name: Routes.DossierDetailsV2,
      params: {
        id: item.item["id"],
      },
    });
  }

  mapToOverviewData: (data: Overview) => Overview = (data: Overview) => {
    return {
      _embedded: {
        results: data._embedded.results.map((result) => {
          return {
            ...result,
            ...{
              data: deepMergeObjects(result.data, result["dynamic_Properties"]),
              id: result.id,
              dossierDefinitionId: result.dossierDefinitionId,
              createdOn: result.createdOn,
              lastModifiedOn: result.lastModifiedOn,
            },
          };
        }),
      },
      _filter: data._filter,
      _page: data._page,
      _sort: data._sort,
    };
  };

  private navigateToSingleDossier() {
    this.singleDossierLoading = true;
    dossierServiceV2
        .getFirstDossier()
        .then((value) => {
          this.singleDossierLoading = false;
          this.singleDossierNotFound = false;
          if (value?._embedded?.results?.length > 0) {
            this.singleDossierNotFound = false;
            router.push({
              name: Routes.DossierDetailsV2,
              params: {
                id: value._embedded.results[0].id,
              },
            });
          } else {
            this.singleDossierNotFound = true;
          }
        })
        .catch((reason) => {
          this.singleDossierLoading = false;
          this.singleDossierNotFound = true;
        });
  }

  private handleDossierSecurity() {
    if (this.activityDefinition.version === 'v1'){
      this.dossierSecurity = defaultDossierSecurity("");
      this.singleDossierLoading = false;
      return;
    }

    const dossierDefintionId = this.activityDefinition.dossiers.entries[0].dossierDefinitionId;
    this.dossierSecurity = this.getDossierSecurity(dossierDefintionId) || defaultDossierSecurity(dossierDefintionId);
  }
}
