






































































































































import DetailComponentBase from "@/app/dynamic-components/details/details-components/detail-component.base";
import {
  DynamicDetailEntry,
  ExchangeDefinitionStep,
} from "@/app/dynamic-components/details/dynamic-detail.model";
import { DetailContext } from "@/app/contexts/detail.context";
import { Component, Vue } from "vue-property-decorator";
import OverviewComponent from "@/app/dynamic-components/overviews/overview-component.vue";
import ExchangeComponent from "@/app/components/exchange/exchange-component.vue";
import FormWrapper from "@/app/dynamic-components/forms/FormWrapper.vue";
import { DynamicForm } from "@/app/dynamic-components/forms/dynamic-form.model";
import { ExternalContext } from "@/app/contexts/externalContext";
import { dossierServiceV2 } from "@/app/services/dossier.service";
import { documentServiceV2 } from "@/app/services/document.service";
import { OverviewControl } from "@/app/dynamic-components/overviews/overiew.model";
import store from "@/store";

export type ListMetaData = {
  categoryId: string;
  translate: boolean;
  types: { [key: string]: string };
};

@Component({
  components: { FormWrapper, ExchangeComponent, OverviewComponent },
})
export default class DetailComponentDocumentChecklist extends DetailComponentBase<ListMetaData> {
  public checklist: {
    label: string;
    id: string;
    present: boolean;
  }[] = [];
  path = `api/v2/documents/metadata`;
  additionalFilters: { [key: string]: string } = {};
  additionalMetadata: { [key: string]: any } = {};

  externalContext: ExternalContext = new ExternalContext();

  showAddFileDialog = false;
  savingDocument = false;
  formAdd: DynamicForm | undefined;

  showViewFileDialog = false;
  formView: DynamicForm | undefined;

  public addDocumentFormDefinition;
  public viewDocumentFormDefinition;

  async postEntityChanged(newValue: DynamicDetailEntry) {
    if (newValue) {
      if (!newValue.metadata.types) {
        newValue.metadata.types = {};
      }
      this.additionalFilters = {
        ae_documentcategory: newValue.metadata.categoryId,
        nrq_regarding: this.detailContext.resolveDataPath("data.dossierId"),
      };
      this.checklist = Object.entries(newValue.metadata.types).map((entry) => {
        return { label: entry[1] as string, id: entry[0], present: false };
      });
      this.addDocumentFormDefinition = this.generateAddDefinition(
        this.entry?.metadata?.categoryId
      );
      this.viewDocumentFormDefinition = this.generateViewDefinition(
        this.entry?.metadata?.categoryId,
        this.rules.canUploadFiles
      );
      this.externalContext.setData("types", this.checklist);
      this.externalContext.setData("categoryId", newValue.metadata.categoryId);
      this.externalContext.setData(
        "referenceId",
        this.detailContext.resolveDataPath("data.dossierId")
      );
      this.externalContext.setData("translate", newValue.metadata.translate);
      this.additionalMetadata.types = this.checklist;
      this.additionalMetadata.translated = newValue.metadata.translate;

      this.resolveChecklist();
    }
  }

  recieveFormAdd(form: DynamicForm) {
    this.formAdd = form;
  }

  recieveFormView(form: DynamicForm) {
    this.formView = form;
  }

  openViewFileDialog(event?: { item: any; index: number }) {
    this.showViewFileDialog = false;
    this.showAddFileDialog = false;

    this.showViewFileDialog = true;
    this.formView?.patchForm(event?.item);
  }
  openAddFileDialog() {
    this.showViewFileDialog = false;
    this.showAddFileDialog = false;

    this.showAddFileDialog = true;
    this.formAdd?.reset();
  }
  closeDialog() {
    this.showViewFileDialog = false;
    this.showAddFileDialog = false;
    this.formView?.reset();
    this.formAdd?.reset();
  }

  private _overviewControl: OverviewControl | undefined;
  recieveOverviewControl(overviewControl: OverviewControl) {
    this._overviewControl = overviewControl;
  }

  async deleteExistingDocument() {
    if (!this.formView) return;
    const item = this.formView?.formData;
    if (!item?.id) return;

    this.savingDocument = true;
    const success = await documentServiceV2.deleteDocumentById(item.id);
    if (success) {
      this.savingDocument = false;
      this.closeDialog();
      this._overviewControl?.refresh();
      this.resolveChecklist();
    }
    this.savingDocument = false;
  }

  async updateExistingDocument() {
    if (!this.formView) return;
    const item = this.formView?.formData;
    if (!item?.id) return;

    const errors = await this.formView.getErrors();
    console.warn("errors", errors, JSON.stringify(errors));
    if (errors.length === 1) {
      this.$toast.error("Validation failed: " + errors[0].description);
      return;
    }
    if (errors.length > 1) {
      this.$toast.error("Multiple validations failed.");
      return;
    }

    this.savingDocument = true;

    const metadata = await documentServiceV2.getDocumentMetadata(item.id[0]);
    metadata.description = item.description;
    metadata.type = item.type;
    const success = await documentServiceV2.updateDocumentMetadata(
      item.id,
      metadata
    );
    if (success) {
      this.savingDocument = false;
      this.closeDialog();
      this._overviewControl?.refresh();
      this.resolveChecklist();
    }
    this.savingDocument = false;
  }

  async addNewDocument() {
    if (!this.formAdd) return;

    const errors = await this.formAdd.getErrors();
    console.warn("errors", errors, JSON.stringify(errors));
    if (errors.length === 1) {
      this.$toast.error("Validation failed: " + errors[0].description);
      return;
    }
    if (errors.length > 1) {
      this.$toast.error("Multiple validations failed.");
      return;
    }

    this.savingDocument = true;

    const beforeSaveSuccess = await this.formAdd.beforeSave();
    if (!beforeSaveSuccess) {
      this.$toast.error("Couldn't save attachments.");

      this.savingDocument = false;
      return;
    }

    Vue.$toast.success("Successfully saved.");

    const afterSaveSuccess = await this.formAdd.afterSave({
      type: "dossier",
      referenceId: this.detailContext.resolveDataPath("data.dossierId"),
    });
    if (!afterSaveSuccess) {
      this.$toast.error("Some post actions failed!");

      this.savingDocument = false;
      return;
    }

    this.savingDocument = false;
    this._overviewControl?.refresh();
    this.resolveChecklist();
    this.closeDialog();
  }

  private generateAddDefinition(categoryId: any) {
    return {
      id: "root",
      name: "",
      type: "LinearSection",
      displayMode: "editable",
      displayModeRule: "",
      computedValue: "",
      metadata: {},
      children: [
        {
          id: "file",
          name: "checklist.add_document_dialog.documents",
          type: "FileInput",
          displayMode: "inherit",
          displayModeRule: "",
          computedValue: "",
          validators: ["required()"],
          metadata: {
            multiple: true,
            categoryId: categoryId,
            typeIdsTemplate: "data.form.checklist",
            descriptionTemplate: "data.form.extraInfo",
            accept: ".xlsx,.docx, .pdf",
          },
        },
        {
          id: "checklist",
          name: "checklist.add_document_dialog.document_type",
          type: "Selectv2",
          displayMode: "inherit",
          displayModeRule: "",
          computedValue: "",
          validators: ["required()"],
          metadata: {
            label: "checklist.add_document_dialog.document_type",
            items: "data.external.types",
            itemText: "label",
            itemValue: "id",
            multiple: true,
            translate: true,
          },
        },
        {
          id: "extraInfo",
          name: "checklist.add_document_dialog.extra_info",
          type: "TextArea",
          displayMode: "inherit",
          displayModeRule: "",
          computedValue: "",
          validators: [],
          metadata: {},
        },
      ],
    };
  }
  private generateViewDefinition(categoryId: any, editable: boolean) {
    return {
      id: "root",
      name: "",
      type: "LinearSection",
      displayMode: "readonly",
      displayModeRule: "",
      computedValue: "",
      metadata: {},
      children: [
        {
          id: "id",
          name: "checklist.view_document_dialog.documents",
          type: "FileInput",
          displayMode: "inherit",
          displayModeRule: "",
          computedValue: "",
          validators: ["required()"],
          metadata: {
            multiple: true,
            categoryId: categoryId,
            typeIdsTemplate: "data.form.checklist",
            descriptionTemplate: "data.form.extraInfo",
            accept: ".xlsx,.docx, .pdf",
          },
        },
        {
          id: "uploadedBy",
          name: "",
          type: "LinearSection",
          displayMode: "inherit",
          displayModeRule: "",
          computedValue: "",
          validators: [],
          metadata: {
            SkipElementAsFormData: true,
            direction: "horizontal",
          },
          children: [
            {
              id: "uploadedBy",
              name: "",
              type: "LinearSection",
              displayMode: "inherit",
              displayModeRule: "",
              computedValue: "",
              validators: [],
              metadata: {},
              children: [
                {
                  id: "contactId",
                  name: "checklist.view_document_dialog.created_by",
                  type: "SelectPerson",
                  displayMode: "inherit",
                  displayModeRule: "",
                  computedValue: "",
                  validators: [],
                  metadata: {},
                },
              ],
            },
            {
              id: "createdOn",
              name: "checklist.view_document_dialog.created_on",
              type: "TextField",
              displayMode: "inherit",
              displayModeRule: "",
              computedValue: "",
              validators: [],
              metadata: {},
            },
          ],
        },
        {
          id: "type",
          name: "checklist.view_document_dialog.document_type",
          type: "Selectv2",
          displayMode: editable ? "editable" : "inherit",
          displayModeRule: "",
          computedValue: "",
          validators: ["required()"],
          metadata: {
            label: "checklist.add_document_dialog.document_type",
            items: "data.external.types",
            itemText: "label",
            itemValue: "id",
            multiple: true,
            translate: true,
          },
        },
        {
          id: "description",
          name: "checklist.view_document_dialog.extra_info",
          type: "TextArea",
          displayMode: editable ? "editable" : "inherit",
          displayModeRule: "",
          computedValue: "",
          validators: [],
          metadata: {},
        },
      ],
    };
  }

  private resolveChecklist() {
    if (!this.entry) return;
    documentServiceV2
      .getAllDocumentsByCategory(
        this.entry.metadata.categoryId,
        this.detailContext.resolveDataPath("data.dossierId")
      )
      .then((allDocs) => {
        const types = new Set();
        allDocs.forEach((value) => {
          if (value.type && value.type.length > 0) {
            value.type.forEach((type) => {
              types.add(type);
            });
          }
        });
        this.checklist.forEach(
          (value) => (value.present = types.has(value.id))
        );
      });
  }
}
