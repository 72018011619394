import { render, staticRenderFns } from "./dossier-edit-view.vue?vue&type=template&id=9043acb0&scoped=true&"
import script from "./dossier-edit-view.vue?vue&type=script&lang=ts&"
export * from "./dossier-edit-view.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9043acb0",
  null
  
)

export default component.exports