




































import { Component } from "vue-property-decorator";
import FormWrapper from "@/app/dynamic-components/forms/FormWrapper.vue";
import OverviewComponent from "@/app/dynamic-components/overviews/overview-component.vue";
import DetailComponentBase from "@/app/dynamic-components/details/details-components/detail-component.base";
import { DynamicForm } from "@/app/dynamic-components/forms/dynamic-form.model";
import { defaultDynamicFormEntry } from "@/app/dynamic-components/dynamic-components.model";
import { DynamicDetailEntry } from "@/app/dynamic-components/details/dynamic-detail.model";
import { RenderItem, RenderType } from "@/app/Types";
import {
  OverviewControl,
  OverviewEvent,
} from "@/app/dynamic-components/overviews/overiew.model";
import { dossierServiceV2 } from "@/app/services/dossier.service";
import Loader from "@/components/common/Loader.vue";
import { DetailContext } from "@/app/contexts/detail.context";
import { ExternalContext } from "@/app/contexts/externalContext";
import ActivityDefinitionModel from "@/app/models/activity/activity-definition.model";
import { Overview } from "@/app/models/overview/overview.model";
import FormDialogComponent from "@/app/components/dialog/form-dialog-component.vue";
import {
  FormDialogControl,
  FormDialogModel,
} from "@/app/components/dialog/form-dialog.model";
import DossierDetailComponent from "@/app/dynamic-components/details/detail-component-dossier.vue";
import { planningService } from "@/app/services/planning.service";
import store from "@/store";

export type DetailModifyEntitiesDialogMetaData = {
  overviewDefinitionId: string;
  selector: string;
};

@Component({
  computed: {},
  components: { FormDialogComponent, Loader, OverviewComponent, FormWrapper },
})
export default class ModifyEntitiesDialog extends DetailComponentBase<DetailModifyEntitiesDialogMetaData> {
  path: string | null = null;

  formDefinition: DynamicDetailEntry = defaultDynamicFormEntry;
  mode: "OVERVIEW" | "MODIFY" = "OVERVIEW";
  isSaving = false;

  private items: any[] = [];

  postEntityChanged(newValue: DynamicDetailEntry) {
    if (
      this.entry &&
      this.entry.children &&
      Array.from(this.entry.children).length === 1
    ) {
      this.formDefinition = this.entry.children[0];
    }
  }

  protected postDetailContextChanged(newValue: DetailContext) {
    if (this.detailContext) {
      const dossierId = this.detailContext.data.dossierId;
      this.createNewLotModel.referenceId = dossierId;
      this.createNewLotModel.externalContext.setData("dossierId", dossierId);
      this.createNewLotModel.externalContext.setData(
        "dossier",
        this.detailContext.data.dossier
      );
      this.modifyLotModel.referenceId = dossierId;
      this.modifyLotModel.externalContext.setData("dossierId", dossierId);
      this.modifyLotModel.externalContext.setData(
        "dossier",
        this.detailContext.data.dossier
      );
      this.path = `api/v2/dossiers/${dossierId}/lots`;

      const items = this.detailContext.resolveDataPath(this.metadata.selector);
      this.items = items;
      console.log(this.detailContext);
    }
  }

  overviewControl: OverviewControl | null = null;
  protected recieveOverviewControl(overviewControl: OverviewControl) {
    this.overviewControl = overviewControl;
  }

  mapToOverviewData(overview: Overview): Overview {
    overview._embedded.results = Array.from(
      overview?._embedded?.results || []
    ).map((value) => dossierServiceV2.convertGetLotToLot(value));
    return overview;
  }

  openCreateLotDialog() {
    this.updatingLotId = null;
    this.updatingLotInitData = null;
    this.createNewLotModel.externalContext.setData("id", null);
    this.createNewLotModel.externalContext.setData("type", null);
    this.createNewcontrol?.show();
  }

  private updatingLotId: string | null = null;
  private updatingLotInitData: any | null = null;
  openUpdateLotDialog(item: { item: any; index: number }) {
    this.updatingLotId = item.item.id;
    this.updatingLotInitData = item.item;
    this.modifyLotModel.externalContext.setData("id", item.item.id);
    this.modifyLotModel.externalContext.setData("type", "lot");
    this.modifyLotcontrol?.show(item.item.id, item.item);
  }

  closeDialog() {
    if(this.detailContext?.root instanceof DossierDetailComponent){
      this.detailContext?.root?.closeModal();
      this.detailContext?.root?.getData();
    }
  }

  async createNewLot(form: DynamicForm, formData: any): Promise<boolean> {
    return dossierServiceV2
      .createLot(form, this.detailContext.data.dossierId, formData)
      .then((value) => {
        //TODO currently hardcoded for riool, needs refactoring in next phases
        if(this.detailContext.data.dossier.general_information.client) {
          planningService.addPartyToPlanning(this.detailContext.data.dossier.general_information.client, '0b38bc90-946d-ee11-9ae6-6045bd97211b', value["dynamic_Properties"].planning, "ORGANISATION");
        }
        this.createNewLotModel.referenceId = value.id;
        return !!value.id;
      });
  }
  async updateNewLot(form: DynamicForm, formData: any): Promise<boolean> {
    if (!this.updatingLotId) return Promise.reject("no lot id");
    return dossierServiceV2
      .updateLot(
        form,
        this.detailContext.data.dossierId,
        this.updatingLotId,
        formData
      )
      .then((value) => {
        this.modifyLotModel.referenceId = value.id;
        return !!value.id;
      });
  }

  async scrapLot(
    form: DynamicForm,
    data: any,
    referenceId: string
  ): Promise<boolean> {
    if (!this.updatingLotId) return Promise.reject("no lot id");
    return dossierServiceV2
      .deleteLot(
        form,
        this.detailContext.data.dossierId,
        this.updatingLotId,
        this.updatingLotInitData
      )
      .then((value) => !!value.id);
  }

  async refreshOverview(): Promise<boolean> {
    this.overviewControl?.refresh();
    return Promise.resolve(true);
  }

  modifyLotcontrol: FormDialogControl | null = null;
  recieveModifyLotDialogControl(control: FormDialogControl) {
    this.modifyLotcontrol = control;
    this.modifyLotcontrol.submitChanges = this.updateNewLot;
    this.modifyLotcontrol.afterSubmitChanges = this.refreshOverview;
    this.modifyLotcontrol.destroyItem = this.scrapLot;
    this.modifyLotcontrol.afterDestroyItem = this.refreshOverview;
  }

  createNewcontrol: FormDialogControl | null = null;

  recieveCreateNewLotDialogControl(control: FormDialogControl) {
    this.createNewcontrol = control;
    this.createNewcontrol.submitChanges = this.createNewLot;
    this.createNewcontrol.afterSubmitChanges = this.refreshOverview;
  }

  createNewLotModel: FormDialogModel = {
    externalContext: new ExternalContext(),
    title: this.$tc("lot.create.title"),
    referenceId: "",
    referenceType: "lot",
    submitLabel: "Create new",
    destroyLabel: null,
    form: {
      id: "modifyLots",
      name: this.$tc("lot.create.lot"),
      type: RenderType.ACORDEON,
      displayMode: "editable",
      displayModeRule: "",
      computedValue: "",
      metadata: {},
      children: [
        {
          id: "general_information",
          name: this.$tc("lot.create.general_information"),
          type: RenderType.SECTION,
          displayMode: "editable",
          displayModeRule: "",
          computedValue: "",
          metadata: {
            SkipElementAsFormData: true,
          },
          children: [
            {
              id: "name",
              name: this.$tc("lot.create.general_information.name"),
              type: RenderType.TEXTFIELD,
              displayMode: "inherit",
              displayModeRule: "",
              computedValue: "",
              validators: ["required()"],
              metadata: {
                isDynamicApiField: false,
              },
            },
            {
              id: "description",
              name: this.$tc("lot.create.general_information.description"),
              type: RenderType.TextArea,
              displayMode: "inherit",
              displayModeRule: "",
              computedValue: "",
              metadata: {
                isDynamicApiField: false,
              },
            },
          ],
        },
        {
          id: "planning",
          name: this.$tc("lot.create.planning"),
          type: RenderType.SECTION,
          displayMode: "editable",
          displayModeRule: "",
          computedValue: "",
          metadata: {
            SkipElementAsFormData: true,
          },
          children: [
            {
              id: "planning",
              name: this.$tc("lot.create.planning.planning"),
              type: RenderType.Planner,
              displayMode: "inherit",
              displayModeRule: "",
              computedValue: "",
              validators: ["required()"],
              metadata: {
                isDynamicApiField: true,
                referenceType: "ae_lot",
                activitiesSelector: "data.form.techniques",
                linkPath:
                  "/my-dossiers/${data.external.dossierId}?lot=${data.external.id}",
                linkName:
                  "${data.external.dossier.general_information.dossier_name} / ${data.form.name}",
              },
            },
          ],
        },
        {
          id: "techniques_and_technique_steps",
          name: this.$tc("lot.create.technics"),
          type: RenderType.SECTION,
          displayMode: "editable",
          displayModeRule: "",
          computedValue: "",
          metadata: {
            SkipElementAsFormData: true,
          },
          children: [
            {
              id: "techniques",
              name: this.$tc("lot.create.technics.technics"),
              type: RenderType.SELECTV2,
              displayMode: "editable",
              displayModeRule: "",
              computedValue: "",
              validators: ["required()"],
              metadata: {
                isDynamicApiField: true,
                items: [
                  "rh.techniques.options.stocking_relining_with_uv",
                  "rh.techniques.options.stocking_relining_with_inversion_stocking",
                  "rh.techniques.options.tube_in_tube_system",
                  "rh.techniques.options.cunettes",
                  "rh.techniques.options.panels",
                  "rh.techniques.options.shotliner",
                  "rh.techniques.options.inox_cuff"
                ],
                multiple: true,
                translate: true
              },
            },
          ],
        },
      ],
    },
  };

  modifyLotModel: FormDialogModel = {
    externalContext: new ExternalContext(),
    title: this.$tc("lot.update.title"),
    referenceId: "",
    referenceType: "lot",
    submitLabel: "Update lot",
    destroyLabel: "Deactivate lot",
    form: {
      id: "modifyLots",
      name: "",
      type: RenderType.ACORDEON,
      displayMode: "editable",
      displayModeRule: "",
      computedValue: "",
      metadata: {},
      children: [
        {
          id: "general_information",
          name: this.$tc("lot.update.general_information"),
          type: RenderType.SECTION,
          displayMode: "editable",
          displayModeRule: "",
          computedValue: "",
          metadata: {
            SkipElementAsFormData: true,
          },
          children: [
            {
              id: "name",
              name: this.$tc("lot.update.general_information.name"),
              type: RenderType.TEXTFIELD,
              displayMode: "inherit",
              displayModeRule: "",
              computedValue: "",
              validators: ["required()"],
              metadata: {
                isDynamicApiField: false,
              },
            },
            {
              id: "description",
              name: this.$tc("lot.update.general_information.description"),
              type: RenderType.TextArea,
              displayMode: "inherit",
              displayModeRule: "",
              computedValue: "",
              metadata: {
                isDynamicApiField: false,
              },
            },
          ],
        },
        {
          id: "planning",
          name: this.$tc("lot.update.planning"),
          type: RenderType.SECTION,
          displayMode: "editable",
          displayModeRule: "",
          computedValue: "",
          metadata: {
            SkipElementAsFormData: true,
          },
          children: [
            {
              id: "planning",
              name: this.$tc("lot.update.planning.planning"),
              type: RenderType.Planner,
              displayMode: "inherit",
              displayModeRule: "",
              computedValue: "",
              validators: ["required()"],
              metadata: {
                isDynamicApiField: true,
                referenceType: "ae_lot",
                activitiesSelector: "data.form.techniques",
                linkPath:
                  "/my-dossiers/${data.external.dossierId}?lot=${data.external.id}",
                linkName:
                  "${data.external.dossier.general_information.dossier_name} / ${data.form.name}",
              },
            },
          ],
        },
        {
          id: "techniques_and_technique_steps",
          name: this.$tc("lot.update.technics"),
          type: RenderType.SECTION,
          displayMode: "editable",
          displayModeRule: "",
          computedValue: "",
          metadata: {
            SkipElementAsFormData: true,
          },
          children: [
            {
              id: "techniques",
              name: this.$tc("lot.update.technics.technics"),
              type: RenderType.SELECTV2,
              displayMode: "editable",
              displayModeRule: "",
              computedValue: "",
              validators: ["required()"],
              metadata: {
                isDynamicApiField: true,
                items: [
                  "rh.techniques.options.stocking_relining_with_uv",
                  "rh.techniques.options.stocking_relining_with_inversion_stocking",
                  "rh.techniques.options.tube_in_tube_system",
                  "rh.techniques.options.cunettes",
                  "rh.techniques.options.panels",
                  "rh.techniques.options.shotliner",
                  "rh.techniques.options.inox_cuff"
                ],
                multiple: true,
                translate: true
              },
            },
          ],
        },
      ],
    },
  };
}
