import { render, staticRenderFns } from "./documents-overview-view.vue?vue&type=template&id=f5fecc60&scoped=true&"
import script from "./documents-overview-view.vue?vue&type=script&lang=ts&"
export * from "./documents-overview-view.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f5fecc60",
  null
  
)

export default component.exports