import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.entry)?_c('div',{staticClass:"me-8 ms-8 mt-4 mb-4"},[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.$t("rh.manage_lots.dialog.title"))+" ")]),(_vm.path)?_c('overview-component',{attrs:{"overview-definition-id":'32f5be61-cf58-ee11-be6e-6045bd895bfb',"path":_vm.path,"can-create-new":true,"modify-fetched-data":_vm.mapToOverviewData,"create-new":{
      title: 'my-dossiers.no-lots',
      subtitle: 'lots.create',
      icon: 'mdi-file-upload',
      largeImage: 'no-dossiers.svg',
    }},on:{"createNewClicked":_vm.openCreateLotDialog,"rowClicked":_vm.openUpdateLotDialog,"overviewControl":_vm.recieveOverviewControl}}):_vm._e(),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":_vm.closeDialog}},[_vm._v(" "+_vm._s(_vm.$t("general.close"))+" ")])],1),_c('form-dialog-component',{attrs:{"form-dialog-model":_vm.createNewLotModel},on:{"formDialogControl":_vm.recieveCreateNewLotDialogControl}}),_c('form-dialog-component',{attrs:{"form-dialog-model":_vm.modifyLotModel},on:{"formDialogControl":_vm.recieveModifyLotDialogControl}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }