import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('overview-component',{attrs:{"overview-definition-id":_vm.entry.metadata.overviewDefinitionId,"embedded-data":_vm.value || [],"clickable":false,"create-new":{
      title: _vm.$t('pf.dossier.atg_overview.no_atgs'),
      subtitle: _vm.$t('pf.dossier.atg_overview.action.add_new_atg'),
      icon: 'mdi-file-upload',
      largeImage: 'no-dossiers.svg',
    }},on:{"createNewClicked":function($event){return _vm.createNewLot()},"actionButtonPressed":_vm.onActionPressed}}),_c('form-component-error-message',{attrs:{"error-messages":_vm.errorMessages}}),_c(VDialog,{attrs:{"fullscreen":_vm.$vuetify.breakpoint.xsOnly,"eager":true,"persistent":"","width":"800"},model:{value:(_vm.showLotForm),callback:function ($$v) {_vm.showLotForm=$$v},expression:"showLotForm"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-md-h5 grey lighten-2"},[_vm._v(" "+_vm._s(_vm.$t("pf.dossiers.add_atg_dialog.title"))+" ")]),(_vm.entry && _vm.entry.metadata && _vm.entry.metadata.subform)?_c(VCol,[_c('FormWrapper',{attrs:{"form-definition":_vm.entry.metadata.subform},on:{"formDataChanged":_vm.formDataChanged,"dynamicForm":_vm.recieveForm}})],1):_c(VCol,[_c('loader',{attrs:{"show":true}})],1),_c(VCardActions,[_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":_vm.closeLotForm}},[_vm._v(" "+_vm._s(_vm.$t("general.cancel"))+" ")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","loading":false},on:{"click":function($event){return _vm.confirmLotForm(this)}}},[_vm._v(" "+_vm._s(_vm.$t("general.submit"))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }