import Vue from "vue";
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from "vue-router";
import { authenticationService } from "@/services/authentication.service";
import PrivacyPolicyView from "@/views/PrivacyPolicyView.vue";
import TermsOfUseView from "@/views/TermsOfUseView.vue";
import DashboardView from "@/views/dashboard/DashboardView.vue";
import MyDocumentsView from "@/views/documents/MyDocumentsView.vue";
import MyDossiersView from "@/views/dossiers/MyDossiersView.vue";
import CreateDossierView from "@/views/dossiers/CreateDossierView.vue";
import DossierDetailsView from "@/views/dossiers/DossierDetailsView.vue";
import LotDetailsView from "@/views/lots/LotDetailsView.vue";
import RelatedDocumentsView from "@/views/dossiers/RelatedDocumentsView.vue";
import SelfMonitoringReportsView from "@/views/lots/SelfMonitoringReportsView.vue";
import DayPlanningHistoryView from "@/views/lots/DayPlanningHistoryView.vue";
import MyInspectionsView from "@/views/inspections/MyInspectionsView.vue";
import { authRoles } from "@/constants/AuthRoles";
import { authorizationService } from "@/services/authorization.service";
import store from "@/store";
import FaqView from "@/views/faq/FaqView.vue";
import NewReleasesView from "@/views/faq/NewReleasesView.vue";
import DossiersOverviewView from "@/app/views/dossiers/dossiers-overview-view.vue";
import DossiersCreateView from "@/app/views/dossiers/dossiers-create-view.vue";
import DossierDetailView from "@/app/views/dossiers/dossier-detail-view.vue";
import DossierEditView from "@/app/views/dossiers/dossier-edit-view.vue";
import DocumentsOverviewView from "@/app/views/documents/documents-overview-view.vue";
import CheckinsOverviewView from "@/app/views/checkins/checkins-overview-view.vue";
import ProductsOverviewView from "@/app/views/products/products-overview-view.vue";
import PlanningsOverviewView from "@/app/views/plannings/plannings-overview-view.vue";
import DashboardV2View from "@/app/views/dashboard/dashboard-v2-view.vue";
import CallbackView from "@/app/views/callback/callback.vue";
import PlaceholderView from "@/app/views/placeholder/placeholder.vue";

Vue.use(VueRouter);

export enum Routes {
  TermsOfUse = "TermsOfUse",
  Placeholder = "Placeholder",
  PrivacyPolicy = "PrivacyPolicy",
  Dashboard = "DashboardV2",
  CreateDossier = "CreateDossier",
  DossierDetails = "DossierDetails",
  RelatedDocuments = "RelatedDocuments",
  LotDetails = "LotDetails",
  SelfMonitoringReports = "SelfMonitoringReports",
  Callback = "Callback",
  DayPlanningHistory = "DayPlanningHistory",
  MyInspections = "MyInspections",
  FAQ = "FAQ",
  Releases = "Releases",

  CreateDossierV2 = "CreateDossierV2",
  CreatePileFoundationDossier = "CreatePileFoundationDossier", // temporary route
  DossierDetailsV2 = "DossierDetailsV2",
  EditDossierV2 = "EditDossierV2",

  //
  MyDossiers = "MyDossiers",
  MyDocuments = "MyDocuments",
  DashboardV2 = "DashboardV2",
  Plannings = "Plannings",
  Checkins = "Checkins",
  Products = "Products",
}

export enum RoutePaths {
  Root = "/",
  Placeholder = '/placeholder',
  TermsOfUse = "/terms-of-use",
  PrivacyPolicy = "/privacy-policy",
  Dashboard = "/dashboard",
  MyDossiers = "/my-dossiers",
  CreateDossier = "/my-dossiers/create&activity=:activityId",
  DossierDetails = "/my-dossiers/dossier/:id",
  RelatedDocuments = "/my-dossiers/dossier/:dossierId/related-documents",
  LotDetails = "/my-dossiers/dossier/:dossierId/lot/:lotId",
  SelfMonitoringReports = "/my-dossiers/dossier/:dossierId/lot/:lotId/self-monitoring-reports",
  MyDocuments = "/my-documents",
  Callback = "/callback",
  DayPlanningHistory = "/my-dossiers/dossier/:dossierId/lot/:lotId/history",
  MyInspections = "/my-inspections",
  FAQ = "/FAQ",
  Releases = "/FAQ/releases",

  CreateDossierV2 = "/my-dossiers/create",
  CreatePileFoundationDossier = "/my-dossiers/create-pile-foundation-dossier", // temporary route
  DossierDetailsV2 = "/my-dossiers/:id",
  EditDossierV2 = "/my-dossiers/:id/edit",
  DashboardV2 = "/dashboard",
  Products = "/products",
  Checkins = "/checkins",
  Plannings = "/plannings",
}

export const RouteConfigs: Array<RouteConfig> = [
  {
    path: RoutePaths.Root,
    async beforeEnter(
      to: Route,
      from: Route,
      next: NavigationGuardNext
    ): Promise<void> {
      await authorizationService.navigateToLandingPage(next);
    },
  },
  {
    path: RoutePaths.Dashboard,
    name: Routes.DashboardV2,
    component: DashboardV2View,
    meta: {
      authorizedRoles: [authRoles.ORGANIZATION_ADMIN, authRoles.EXECUTOR],
      navigationTab: {
        legacy: true,
        translationKey: "dashboard.page-title",
        icon: "mdi-home",
        menuName: "primaryNavigation",
      },
    },
  },
  {
    path: RoutePaths.MyDocuments,
    name: Routes.MyDocuments,
    component: DocumentsOverviewView,
    meta: {
      authorizedRoles: [
        authRoles.ORGANIZATION_ADMIN,
        authRoles.EXECUTOR,
        authRoles.INSPECTOR,
      ],
      navigationTab: {
        legacy: true,
        translationKey: "my-documents.page-title",
        icon: "mdi-file-document",
        menuName: "primaryNavigation",
      },
    },
  },
  {
    path: RoutePaths.MyDossiers,
    name: Routes.MyDossiers,
    component: DossiersOverviewView, //MyDossiersView,
    meta: {
      authorizedRoles: [
        authRoles.ORGANIZATION_ADMIN,
        authRoles.EXECUTOR,
        authRoles.INSPECTOR,
      ],
      authorizationBusinessRules: [
        (): boolean => store.state.userDossierAccess.hasAccessToDossiers,
      ],
      navigationTab: {
        legacy: true,
        translationKey: "my-dossiers.page-title",
        icon: "mdi-folder",
        menuName: "primaryNavigation",
      },
    },
  },
  {
    path: RoutePaths.Checkins,
    name: Routes.Checkins,
    component: CheckinsOverviewView,
    meta: {
      navigationTab: {
        translationKey: "checkins.page-title",
        icon: "mdi-arrow-down-bold-hexagon-outline",
        menuName: "primaryNavigation",
      },
    },
  },
  {
    path: RoutePaths.Products,
    name: Routes.Products,
    component: ProductsOverviewView,
    meta: {
      navigationTab: {
        translationKey: "products.page-title",
        icon: "mdi-bolt",
        menuName: "primaryNavigation",
      },
    },
  },
  {
    path: RoutePaths.Plannings,
    name: Routes.Plannings,
    component: PlanningsOverviewView,
    meta: {
      navigationTab: {
        translationKey: "planning.page-title",
        icon: "mdi-notebook-outline",
        menuName: "primaryNavigation",
      },
    },
  },
  {
    path: RoutePaths.CreateDossier,
    name: Routes.CreateDossier,
    component: CreateDossierView,
    props: true,
    meta: {
      authorizedRoles: [authRoles.ORGANIZATION_ADMIN, authRoles.EXECUTOR],
      authorizationBusinessRules: [
        (): boolean => store.state.userDossierAccess.hasAccessToDossiers,
      ],
    },
  },
  {
    path: RoutePaths.DossierDetails,
    name: Routes.DossierDetails,
    component: DossierDetailsView,
    props: true,
    meta: {
      authorizedRoles: [
        authRoles.ORGANIZATION_ADMIN,
        authRoles.EXECUTOR,
        authRoles.INSPECTOR,
      ],
      authorizationBusinessRules: [
        (): boolean => store.state.userDossierAccess.hasAccessToDossiers,
      ],
    },
  },
  {
    path: RoutePaths.RelatedDocuments,
    name: Routes.RelatedDocuments,
    component: RelatedDocumentsView,
    props: true,
    meta: {
      authorizedRoles: [
        authRoles.ORGANIZATION_ADMIN,
        authRoles.EXECUTOR,
        authRoles.INSPECTOR,
      ],
      authorizationBusinessRules: [
        (): boolean => store.state.userDossierAccess.hasAccessToDossiers,
      ],
    },
  },
  {
    path: RoutePaths.LotDetails,
    name: Routes.LotDetails,
    component: LotDetailsView,
    props: true,
    meta: {
      authorizedRoles: [
        authRoles.ORGANIZATION_ADMIN,
        authRoles.EXECUTOR,
        authRoles.INSPECTOR,
      ],
      authorizationBusinessRules: [
        (): boolean => store.state.userDossierAccess.hasAccessToDossiers,
      ],
    },
  },
  {
    path: RoutePaths.SelfMonitoringReports,
    name: Routes.SelfMonitoringReports,
    component: SelfMonitoringReportsView,
    props: true,
    meta: {
      authorizedRoles: [
        authRoles.ORGANIZATION_ADMIN,
        authRoles.EXECUTOR,
        authRoles.INSPECTOR,
      ],
      authorizationBusinessRules: [
        (): boolean => store.state.userDossierAccess.hasAccessToDossiers,
      ],
    },
  },
  {
    path: RoutePaths.DayPlanningHistory,
    name: Routes.DayPlanningHistory,
    component: DayPlanningHistoryView,
    props: true,
    meta: {
      authorizedRoles: [
        authRoles.ORGANIZATION_ADMIN,
        authRoles.EXECUTOR,
        authRoles.INSPECTOR,
      ],
      authorizationBusinessRules: [
        (): boolean => store.state.userDossierAccess.hasAccessToDossiers,
      ],
    },
  },
  {
    path: RoutePaths.FAQ,
    name: Routes.FAQ,
    component: FaqView,
    meta: {
      authorizedRoles: [
        authRoles.ORGANIZATION_ADMIN,
        authRoles.EXECUTOR,
        authRoles.INSPECTOR,
      ],
      navigationTab: {
        translationKey: "FAQ.page-title",
        icon: "mdi-help-circle-outline",
        menuName: "helpNavigation",
      },
    },
  },
  {
    path: RoutePaths.Releases,
    name: Routes.Releases,
    component: NewReleasesView,
    meta: {
      authorizedRoles: [
        authRoles.ORGANIZATION_ADMIN,
        authRoles.EXECUTOR,
        authRoles.INSPECTOR,
      ],
      navigationTab: {
        translationKey: "new-releases.page-title",
        icon: "mdi-star",
        menuName: "helpNavigation",
      },
    },
  },
  {
    path: RoutePaths.MyInspections,
    name: Routes.MyInspections,
    component: MyInspectionsView,
    meta: {
      authorizedRoles: [authRoles.INSPECTOR],
      navigationTab: {
        legacy: true,
        translationKey: "my-inspections.page-title",
        icon: "mdi-magnify",
        menuName: "primaryNavigation",
      },
      authorizationBusinessRules: [
        (): boolean => store.state.userDossierAccess.hasAccessToDossiers,
      ],
    },
  },
  {
    path: RoutePaths.TermsOfUse,
    name: Routes.TermsOfUse,
    component: TermsOfUseView,
  },
  {
    path: RoutePaths.PrivacyPolicy,
    name: Routes.PrivacyPolicy,
    component: PrivacyPolicyView,
  },
  {
    path: RoutePaths.Placeholder,
    name: Routes.Placeholder,
    component: PlaceholderView,
  },
  {
    path: RoutePaths.Callback,
    name: Routes.Callback,
    component: CallbackView,
    beforeEnter(
      to: Route,
      from: Route,
      next: NavigationGuardNext
    ): Promise<void> {
      return authenticationService.handleRouteCallback(next);
    },
  },
  {
    path: RoutePaths.CreateDossierV2,
    name: Routes.CreateDossierV2,
    component: DossiersCreateView,
  },
  {
    path: RoutePaths.DossierDetailsV2,
    name: Routes.DossierDetailsV2,
    component: DossierDetailView,
  },
  {
    path: RoutePaths.EditDossierV2,
    name: Routes.EditDossierV2,
    component: DossierEditView,
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: RouteConfigs,
});

router.beforeEach(async (to, from, next) => {
  if(!store.state.user.roles){
    await authenticationService.acquireToken();
  }
  await authorizationService.handleRoleBasedNavigation(to, next);
  await authorizationService.handleBusinessRulesBasedNavigation(to, next);
});

export default router;
